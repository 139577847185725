import { Intercom, onHide, show } from '@intercom/messenger-js-sdk';
import type {
  MinimumBootArgs,
  Regions,
  UserArgs,
} from '@intercom/messenger-js-sdk/dist/types';
import { type FC, useEffect } from 'react';
import { useIsClient, useLocalStorage } from 'usehooks-ts';

export type IntercomClientProps = {
  appId: MinimumBootArgs['app_id'];
  region?: Regions;
  user?: UserArgs | null;
  defaultExpanded?: boolean;
  defaultExpandedDelay?: number;
};

export const IntercomClient: FC<IntercomClientProps> = ({
  appId,
  defaultExpanded,
  user,
  defaultExpandedDelay = 0,
  region = 'us',
}) => {
  const isClient = useIsClient();
  const [isRememberedClosed, setIsRememberedClosed] = useLocalStorage(
    'intercomClosed',
    false
  );

  if (isClient) {
    Intercom({
      region,
      app_id: appId,
      user_id: user?.userId,
      email: user?.email,
      name: user?.name,
      created_at: user?.createdAt,
    });
    onHide(() => {
      if (!isRememberedClosed) setIsRememberedClosed(true);
    });
  }

  useEffect(() => {
    if (!defaultExpanded || isRememberedClosed) return;

    setTimeout(show, defaultExpandedDelay);
  }, [defaultExpanded, defaultExpandedDelay, isRememberedClosed]);

  return null;
};
